// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("trix")
require("@rails/actiontext")
require("readmore-js")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('bootstrap')
import '../stylesheets/application'
import '@fortawesome/fontawesome-free/css/all.min'

import smoothscroll from 'smoothscroll-polyfill';
// or if linting/typescript complains
//import * as smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

$(document).on('ready turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('article').readmore({
    speed: 75,
    moreLink: '<a href="#">Weiterlesen</a>',
    lessLink: '<a href="#">Weniger</a>'
  });
});
